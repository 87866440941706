// JsFromRoutes CacheKey 22a398097b8a85a8547b747145436a87
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  taggedClausesWithDeviations: definePathHelper('get', '/contract_summaries/summary_documents/tagged_clauses_with_deviations'),
  create: definePathHelper('post', '/contract_summaries/summary_documents'),
  get: definePathHelper('get', '/contract_summaries/summary_documents/:id'),
}
